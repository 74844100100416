.hoverBtn:hover{
    cursor:pointer;
}

.previewVideo{
    max-width: max-content;
}

.videoPlay{
    position: relative;
}

.close_icon{
    position: absolute;
    right: 0;
    background: #fff;
    border-radius: 0px;
    width: 30px;
    padding: 0px 3px 3px 10px;
    border-bottom-left-radius: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 999;
}

.videoPlay .close_icon svg{
    position: absolute;
    right: 0;
    background: #fff;
    border-radius: 0px;
    width: 30px;
    padding: 0px 3px 3px 10px;
    border-bottom-left-radius: 30px;
    height: 30px;
    cursor: pointer;
}

.videoPlay .close_icon:hover svg{
        fill: #fff;
        background: red;
}


.multiple_close_icon{
    position: absolute;
    right: 8px;
    top: 4px;
    background: #969292;
    border-radius: 0px;
    width: 15px;
    padding: 0px 3px 3px 5px;
    border-bottom-left-radius: 30px;
    height: 15px;
    cursor: pointer;
    z-index: 999;
}

.multipleVideoPlay .multiple_close_icon svg{
    position: absolute;
    right: 0;
    background: #fff;
    border-radius: 0px;
    width: 25px;
    padding: 0px 2px 2px 7px;
    border-bottom-left-radius: 30px;
    height: 25px;
    cursor: pointer;
}

.multipleVideoPlay .multiple_close_icon:hover svg{
        fill: #fff;
        background: red;
}

/****************pagination*****************/

.pagination {
    display: flex;
    border-radius: 6px;
    padding: 10px 0px 0px;
    width: 100%;
    justify-content: end;
    margin-bottom: 0px;
}

.pagination li a{
    padding: 8px 15px;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #5C5656;
}

.pagination li.selected a{
    color: #ae2f34 !important;
    background-color: transparent !important;
    font-size: 18px !important;
    font-weight: 700;
}

.pagination li.previous a{
    padding: 0.5px 9px;
    background-color: #fff;
    border-radius: 5px;
    margin-top: -2px;
    font-size: 20px;
    box-shadow: 29px;
    box-shadow: 0px 3px 14px #00000066;
}

.pagination li.previous a i{
    font-weight: 900;
}
.pagination li.next a{
    padding: 0.5px 9px;
    background-color: #fff;
    border-radius: 5px;
    margin-top: -2px;
    font-size: 20px;
    box-shadow: 29px;
    box-shadow: 0px 3px 14px #00000066;
}

.pagination li.next a i{
    font-weight: 900;
}

.mfilter ul{
    list-style-type: none;
    font-size: 10px;
    margin: 0px;
    padding: 0px;
    display: flex;
    flex-wrap: wrap;
}
.mfilter ul li{
    color: #000;
    cursor: pointer;
    padding-right: 5px;
    padding-bottom: 5px;
    margin-bottom: 5px;
}

span.filterin {
    background: #d0d0d0;
    padding: 3px 5px 3px 7px;
    cursor: pointer;
    border-radius: 3px;
}
span.filterin span{
    width: 14px;
    height: 14px;
    line-height: 14px;
    background: #fff;
    color: #000;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    font-size: 9px;
    margin-left: 3px;
}

/****************pagination*****************/

.row.changePassword > div {
    margin-bottom: 40px;
}
.row.createUser > div {
    margin-bottom: 40px;
}

.wrongPassword{
    font-size: 16px;
    font-weight: 500;
}
.rightPassword{
        font-size: 20px;
        font-weight: 500;
}

.dropdown-menu[data-bs-popper] {
    left: -30px !important;
}

.MuiIcons{
    margin-left: 10px;
}
/* 
.filterbx {
    max-height: 350px;
    height: 100%;
    overflow-y: auto;
} */

.cartScrollBar{
    max-height: 80vh;
    overflow-y: auto;
    height: 100%;
}

 .cartScrollBar::-webkit-scrollbar {
    width: 5px;
  }
  
  .cartScrollBar::-webkit-scrollbar-track {
    background-color: #dfdfdf; 
    border-radius: 3px;
  }
  
  .cartScrollBar::-webkit-scrollbar-thumb {
    background-color: #adadad;
    border-radius: 3px;
  }

  .categoryTagsList{
    max-height: 400px;
    overflow-y: auto;
    margin-top: 10px;
  }
  .categoryTagsList::-webkit-scrollbar {
    width: 5px;
  }
  
  .categoryTagsList::-webkit-scrollbar-track {
    background-color: #dfdfdf; 
    border-radius: 3px;
  }
  
  .categoryTagsList::-webkit-scrollbar-thumb {
    background-color: #adadad;
    border-radius: 3px;
  }
  .saveNewTag{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .tagName{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .AddCategoryTag{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .AddCategoryTagButton:hover , .tagNameTagButton:hover , .saveIcon:hover{
    cursor: pointer;
  }
